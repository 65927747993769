import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountPageModule),
  },
  {
    path: 'times',
    loadChildren: () =>
      import('./times/times.module').then((m) => m.TimesPageModule),
  },
  {
    path: 'rodadas',
    loadChildren: () =>
      import('./rodadas/rodadas.module').then((m) => m.RodadasPageModule),
  },
  {
    path: 'venda',
    loadChildren: () =>
      import('./venda/venda.module').then((m) => m.VendaModule),
  },
  {
    path: 'bilhete',
    loadChildren: () =>
      import('./bilhetes/bilhete.module').then((m) => m.BilheteModule),
  },
  {
    path: 'resultado',
    loadChildren: () =>
      import('./resultado/resultado.module').then((m) => m.ResultadoModule),
  },
  {
    path: 'usuarios',
    loadChildren: () =>
      import('./usuarios/usuarios.module').then((m) => m.UsuariosModule),
  },
  {
    path: 'relatorios',
    loadChildren: () =>
      import('./relatorios/relatorios.module').then((m) => m.RelatoriosModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
