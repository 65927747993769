import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storage: Storage;

  constructor(
  ) {
    this.storage = window.localStorage;
  }

  set(key: string, value: any) {
    this.storage.setItem(key, value);
  }

  get(key) {
    return this.storage.getItem(key);
  }
}
