// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  pagamentoURL: 'https://bolaodofuturo.com.br/pagamento/',
  supabaseUrl: 'https://bolaodofuturo.com.br/api',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzEwNTU4MDAwLAogICJleHAiOiAxODY4MzI0NDAwCn0.MH7hNHy6SD5t1dyxzD-DdlisQqH5UGgsYTfRQNAlP60',
  footbolDataAuth: '3802ecd0f99b44369798c74aa52ec75c',
  footbolDataUrl: 'https://api.football-data.org/v4/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
