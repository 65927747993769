import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import {
  AuthChangeEvent,
  createClient,
  Session,
  SupabaseClient,
} from '@supabase/supabase-js';
import { environment } from '../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';

export interface Profile {
  id?: string;
  username: string;
  website: string;
  avatar_url: string;
  admin: boolean;
  vendedor: boolean;
  setor: boolean;
  setorista: string;
}

export interface Time {
  id?: string;
  nome: string;
  img: string;
  sigla: string;
  _img?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private supabase: SupabaseClient;

  constructor(
    private readonly dom: DomSanitizer,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController
  ) {
    this.supabase = createClient(
      environment.supabaseUrl,
      environment.supabaseKey
    );
  }

  get user() {
    return this.supabase.auth.user();
  }

  get session() {
    return this.supabase.auth.session();
  }

  get profile() {
    return this.supabase
      .from('profiles')
      .select(`username, website, avatar_url, admin`)
      .eq('id', this.user?.id)
      .single();
  }

  authChanges(
    callback: (event: AuthChangeEvent, session: Session | null) => void
  ) {
    return this.supabase.auth.onAuthStateChange(callback);
  }

  async rpc(functionName: string, params: any) {
    let { data, error } = await this.supabase.rpc(functionName, params);
    if (error) {
      console.info('rpc error', error);
    }
  }

  signIn(email: string, password: string) {
    return this.supabase.auth.signIn({ email, password });
  }

  signOut() {
    return this.supabase.auth.signOut();
  }

  updateProfile(profile: Profile) {
    const update = {
      ...profile,
      id: this.user?.id,
      updated_at: new Date(),
    };

    return this.supabase.from('profiles').upsert(update, {
      returning: 'minimal', // Don't return the value after inserting
    });
  }

  updateDb(table: string, updateObject: any) {
    return this.supabase.from(table).update(updateObject);
  }

  insertDb(table: string, updateObject: any) {
    return this.supabase.from(table).insert(updateObject);
  }
  downLoadImage(path: string) {
    return this.supabase.storage.from('avatars').download(path);
  }

  uploadAvatar(filePath: string, file: File) {
    return this.supabase.storage.from('avatars').upload(filePath, file);
  }

  uploadTime(filePath: string, file: File) {
    return this.supabase.storage.from('times').upload(filePath, file);
  }

  downLoadClubeImageFromSupabase(path: string) {
    return this.supabase.storage.from('times').download(path);
  }

  async downloadClubImage(path: string) {
    try {
      const { data } = await this.downLoadClubeImageFromSupabase(path);
      return this.dom.bypassSecurityTrustResourceUrl(
        URL.createObjectURL(data)
      );
    } catch (error) {
      console.error('Error downloading image: ', error.message);
    }
  }

  getPublicURL(path: string) {
    return this.supabase.storage.from('times').getPublicUrl(path);
  }
  getFromSelect(from: string, select: string) {
    return this.supabase.from(from).select(select);
  }

  async createNotice(message: string) {
    const toast = await this.toastCtrl.create({ message, duration: 5000 });
    await toast.present();
  }

  createLoader() {
    return this.loadingCtrl.create();
  }

  async removerDb(tabela: string, objeto: any) {
    await this.supabase.from(tabela).delete().eq('id', objeto.id);
  };

  async removeItemTime(item: any) {
    await this.supabase.storage.from('times').remove([item.img]);
    await this.supabase.from('times').delete().eq('id', item.id);
    return;
  }

}
