import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Profile, SupabaseService} from './supabase.service';
import {LocalStorageService} from './localstorage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  session = this.supabase.session;
  profile: Profile;
  public appPages = [
    {
      title: 'Home',
      url: '/',
      icon: 'home',
      abaixo: false
    },
    {
      title: 'Como funciona',
      url: '/sobre',
      icon: 'newspaper',
      abaixo: false
    },
    {
      title: 'Contato',
      url: '/contato',
      icon: 'arrow-redo',
      abaixo: false
    },
  ];
  public adminPages = [
    // {
    //   title: 'Perfil',
    //   url: '/account',
    //   icon: 'person-circle',
    // },
    {
      title: 'Clubes',
      url: '/times',
      icon: 'flag',
    },
    {
      title: 'Rodadas',
      url: '/rodadas',
      icon: 'book',
    },
    {
      title: 'Resultado',
      url: '/resultado',
      icon: 'play',
    },
    {
      title: 'Usuarios',
      url: '/usuarios',
      icon: 'person',
    },
    {
      title: 'Relatorios',
      url: '/relatorios',
      icon: 'book',
    },
  ];
  public vendedorPages = [
    {
      title: 'Venda',
      url: '/venda',
      icon: 'card',
    },
    {
      title: 'Relatorios',
      url: '/relatorios',
      icon: 'book',
    },
  ];

  constructor(
    private supabase: SupabaseService,
    private route: ActivatedRoute,
    private storage: LocalStorageService,
    private router: Router
  ) {

    route.queryParams
      .subscribe(data=> {
        if (data.code) {
          this.storage.set('indicacao', data.code);
        }
      });

    this.supabase.authChanges(async (_, session) => {
      this.session = session;

      if (!session?.user) {
        return;
      }

      if (router.url === '/login') {
        this.router.navigate(['/']);
      }

      let {data: profile, status, error} = await supabase.getFromSelect('profiles', '*')
        .eq('id', session.user.id).single()
      ;

      if (error) {
        supabase.createNotice(error.message);
      }
      this.profile = profile;
      this.session.user.user_metadata = profile;
    });
  }

  logout() {
    this.supabase.signOut();
    this.router.navigate(['/'], {});
  }
}
